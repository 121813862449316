const CapsLeft: React.FunctionComponent = () => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M87 87L183.5 87"
        stroke="white"
        strokeWidth="173"
        strokeLinecap="square"
      />
    </>
  );
};

export default CapsLeft;
